<div class="gallery {{manualClass}}" >
    <button *ngIf="item"
            type="button"
            class="button-delete"
            [class.hide]="readonly"
            (click)="onRemoveClick()"></button>

    <div *ngIf="item && item.type === 'image'"
         class="gallery-selecter-img"
         [class.disabled]="readonly"
         [ngStyle]="{'background-image': 'url(' + item.thumbnail_url + ')'}"></div>

    <!-- <div *ngIf="item && item.type === 'document'"
             class="gallery-selecter-img"
             [class.disabled]="readonly"
             [ngStyle]="{'background-image': 'url(' + item.thumbnail_url + ')'}"></div> -->

    <div *ngIf="item && item.type === 'video'">
        <div *ngIf="!item.playMode"
             class="gallery-selecter-img thumbnail video"
             [ngStyle]="{'background-image': 'url(' + item.thumbnail_url + ')'}">
            <button *ngIf="!item.playMode"
                    type="button"
                    class="button-gallery"
                    (click)="switchPlayMode(item)">
                <img src="assets/icons/play-white.png" />
            </button>
        </div>
        <video controls
               *ngIf="item.playMode"
               class="gallery-selecter-img video"
               #videoPlayer>
            <source [src]="item.url"
                    [type]="item.mime_type">
            Your browser does not support the video tag.
        </video>
        <span class="gallery-selecter-img details">
            Size: {{item.size}}
            <button *ngIf="item.mime_type !== 'application/x-mpegURL'"
                        type="button"
                        title="Fix video"
                        class="button-gallery"
                        (click)="onFixVideo(item.id)">
                        <img src="assets/icons/ic_fix.png" /></button>
        </span>
    </div>
    
    <button *ngIf="!item"
            type="button"
            [disabled]="readonly"
            class="gallery-button"
            (click)="openGallery()">
        <img *ngIf="type === 'video'" class="gallery-button-img" src="assets/icons/ic_gallery_add_video.svg" />
        <img *ngIf="type !== 'video'" class="gallery-button-img" src="assets/icons/ic_gallery_add_image.svg" />
        
        <p class="gallery-button-p">Click to add
            <span *ngIf="type === 'image'">an image</span>
            <span *ngIf="type === 'video'">a video</span>
            <span *ngIf="type === 'document'">a file</span>
            <span *ngIf="type === 'all'">an image or video</span>
        </p>
        
        <span *ngIf="description" class="gallery-button-description">
            {{ description }}
        </span>
    </button>
</div>